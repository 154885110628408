import { atom, selector, selectorFamily } from 'recoil';
import { Scenario, ScenarioLevels } from './features/scenarios/types';
import formatTime from './features/common/helpers/formatTime.helper';
import { getDefaultGamePlay } from './features/common/helpers/scenarios.helper';
import { Maybe, PlayerRoles } from './features/common/types';
import { getAtom } from './persistent-state/persistent-state';
import {
  StaffWithImageUrl,
  WordpressContent,
  WordpressLevel,
} from './features/content/types';
import { Report } from './features/reports/types';
import { AwardList } from './features/awards/types';
import { User } from './features/common/hooks/useUser';

export type StartedScenario = {
  scenario: Scenario;
  level: ScenarioLevels;
};

export const startedScenarioAtom = atom<Maybe<StartedScenario>>({
  key: 'startedScenarioAtom',
  default: undefined,
});

export const isVideoModalOpenedAtom = atom<boolean>({
  key: 'isVideoModalOpenedAtom',
  default: false,
});

export enum GameStatus {
  Ready = 'ready',
  Playing = 'playing',
  Paused = 'paused',
  Aborted = 'aborted',
  Success = 'success',
  Failure = 'failure',
  // @deprecated - remove when backend is ready
  Complete = 'completed',
}

export type GamePlayCommon = {
  scenario: string;
  level: string;
  status: GameStatus;
};

export type GamePlay = GamePlayCommon & {
  gameRemainingSeconds: number;
};

export type GamePlayTopicResponse = GamePlayCommon & {
  game_remaining_seconds: string;
};

export const gamePlayAtom = atom<GamePlay>({
  key: 'gamePlayAtom',
  default: getDefaultGamePlay(),
});

export const sessionStateAtom = atom<boolean>({
  key: 'sessionStateAtom',
  default: false,
});

export const gamePlayTimeLeftSelector = selector<string>({
  key: 'gamePlayTimeLeftSelector',
  get: ({ get }) => {
    const gamePlay = get(gamePlayAtom);
    return formatTime(gamePlay.gameRemainingSeconds);
  },
});

export const gamePlayStatusSelector = selector<GameStatus>({
  key: 'gamePlayStatusSelector',
  get: ({ get }) => {
    const gamePlay = get(gamePlayAtom);
    return gamePlay.status;
  },
});

export const playerRoleSelector = selector<Maybe<PlayerRoles>>({
  key: 'playerRoleSelector',
  get: ({ get }) => {
    const role = get(getAtom('selectedPlayerRole'));
    return role;
  },
});

export const staffAtom = atom<StaffWithImageUrl[]>({
  key: 'staffAtom',
  default: [],
});

export const scenariosAtom = atom<Scenario[]>({
  key: 'scenariosAtom',
  default: [],
});

export const scenarioAtom = atom<Maybe<Scenario>>({
  key: 'scenarioAtom',
  default: undefined,
});

export const scenarioSelector = selectorFamily({
  key: 'scenarioSelector',
  get:
    (slug: string) =>
    ({ get }) => {
      const scenario = get(scenarioAtom);
      if (scenario) {
        return scenario;
      }
      const scenarios = get(scenariosAtom);
      if (!scenarios || scenarios.length === 0) {
        return undefined;
      }
      return scenarios.find((s: Scenario) => s.id === slug);
    },
});

export const levelsAtom = atom<WordpressContent<WordpressLevel>[]>({
  key: 'levelsAtom',
  default: [],
});

export const reportsAtom = atom<Maybe<Report[]>>({
  key: 'reportsAtom',
  default: undefined,
});
export const awardsAtom = atom<Maybe<AwardList>>({
  key: 'awardsAtom',
  default: undefined,
});

export const userAtom = atom<Maybe<User>>({
  key: 'userAtom',
  default: undefined,
});

export const reportsSelectedUserEmailSelector = selector<Maybe<string>>({
  key: 'reportsSelectedUserEmailSelector',
  get: ({ get }) => {
    const user = get(userAtom);
    const reportsSelectedUserEmail = get(getAtom('reportsSelectedUserEmail'));
    return reportsSelectedUserEmail || user?.email;
  },
});

export const reportsLoadingAtom = atom<boolean>({
  key: 'reportsLoadingAtom',
  default: false,
});

export const awardsLoadingAtom = atom<boolean>({
  key: 'awardsLoadingAtom',
  default: false,
});
